import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { textAlign } from '@mui/system';
const BASE_API ='https://api.careernaksha.com';

//const BASE_API ='http://localhost:3000';


const Plan1 = () => {
    const { userInfo } = useSelector((state) => state.user);
    const [grade, setGrade] = useState('E');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {
            const script = document.createElement("script");
            script.src="https://checkout.razorpay.com/v1/payment-button.js";
            script.async=true;
            script.dataset.payment_button_id="pl_L4maaiJOwSAyE9";
            rzpPaymentForm.appendChild(script);
        }

    }, [])
    

    // function loadRazorpay() {
    //     console.log(grade);
    //     const script = document.createElement('script');
    //     script.src = 'https://checkout.razorpay.com/v1/checkout.js';

    //     script.onerror = () => {
    //         alert('Razorpay SDK Failed To Load Are You Online ?');
    //     };

    //     script.onload = async () => {
    //         try {
    //             const result = await axios.post(
    //                 BASE_API+'/api/communityPsychometrics/create-order',
    //                 {
    //                     amount: 499 + '00',
    //                 }
    //             );

    //             const { amount, id: order_id, currency } = result.data;
    //             let uuid;
    //             let d = new Date();
    //             uuid = d.valueOf();
    //             const options = {
    //                 key: 'rzp_live_LaM5pgz5HtEDdv',
    //                 amount: amount.toString(),
    //                 currency: currency,
    //                 name: 'Plan Solar',
    //                 description: 'Detailed Assessment + Report',
    //                 order_id: order_id,
    //                 handler: async function (response) {
    //                     const result = await axios.post(
    //                         BASE_API+'/api/horoscopePsychometrics/detailed-test',
    //                         {
    //                             amount: amount,
    //                             razorpayPaymentId: response.razorpay_payment_id,
    //                             razorpayOrderId: response.razorpay_order_id,
    //                             razorpaySignature: response.razorpay_signature,
    //                             uuid: uuid,
    //                             email: email,
    //                             phone: userInfo.data.number,
    //                             primary: grade,
    //                         }
    //                     );
    //                     navigate('/thankyou');
    //                 },
    //             };
    //             const paymentObject = new window.Razorpay(options);
    //             paymentObject.open();
    //         } catch (error) {
    //             alert(error);
    //         }
    //     };

    //     document.body.appendChild(script);
    // }
    return (
        <div>
            <div className="plan-1">
                <div className="container">
                    <h2>Kindly Provide Your Details</h2>

                    <div className="plan-1-form">
                        <label>Email ID</label>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Category</label>
                        <select
                            onChange={(e) => {
                                if (e.target.value === '8 & below') {
                                    setGrade('A');
                                }  
                                else if(e.target.value === '10'){
                                    setGrade('B')
                                }
                                
                                else if (e.target.value === '11 & 12') {
                                    setGrade('C');
                                } else if (e.target.value === 'graduate') {
                                    setGrade('D');
                                } else {
                                    setGrade('E');
                                }
                            }}
                        >
                            <option value="professional">
                                Working Professional
                            </option>
                            <option value="graduate">Graduate UG/PG</option>
                            <option value="11 & 12">Class 11th & 12th</option>
                            <option value="10">Class 9th and 10th</option>
                            <option value="8 & below">Class 8th & Below</option>
                        </select>


                            <form id='rzp_payment_form' style={{textAlign:'center'}}>


                            </form>

                        {/* <button
                            onClick={() =>
                                email !== '' && grade !== ''
                                    ? loadRazorpay()
                                    : alert('Fill the form')
                            }
                        >
                            Buy Now
                        </button> */}
                        <button onClick={() => navigate('/payment')}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plan1;

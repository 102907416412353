import React from 'react';
import SubLogin from './sub-component/SubLogin';

const ReportSignIn = () => {
  const screen = window.screen.width;
  return (
    <>
      {screen <= 480 ? (
        <SubLogin />
      ) : (
        <div>
          <div className="report-sign-container">
            <div className="sign-in">
              <div className="sign-text">
                <p>You are one step away from seeing your cards.</p>
                <p>Checkout your career horoscope now!</p>
              </div>
              <SubLogin />
            </div>
          </div>

          <div className="background">
            <img src="./img/Report/Hero banner background.png" alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportSignIn;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Assesment.css';

const BASE_API = 'https://api.careernaksha.com';

// const BASE_API = 'http://localhost:3000';
const addtionalQuestion = [
  'Are you bored in your current job?',
  'Are you feeling stuck in your career?',
  'Do you need new skill or course to learn?',
  'Want to get a promotion?',
  'Looking to get associated with a brand?',
];

const AssessmentPage = () => {
  const navigate = useNavigate();

  const [questionSet, setQuestionSet] = useState(1);
  const [questions, setQuestions] = useState();
  const [clicked, setClicked] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState(new Array(30));
  const [currentAdditinalQuestion, setCurrentAdditinalQuestion] = useState(0);
  const [additionalAnswer, setAdditionalAnswer] = useState(new Array(5));

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const uid = urlParams.get('uid');
  const dashboardId = urlParams.get('dashboard');

  const getQuestions = async () => {
    await axios
      .get(BASE_API + '/assessment')
      .then((res) => {
        setQuestions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getUserData = useCallback(async () => {
    await axios
      .post(`${BASE_API}/userdetails/getVerandaUser`, {
        uid,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.ResponseString) {
            navigate({ pathname: '/report', search: `?uid=${uid}` });
          } else {
            sendUserUpdateToVeranda();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [navigate, uid]);

  const getDashboardUserData = useCallback(async () => {
    console.log(dashboardId);
    await axios
      .post(`${BASE_API}/userdetails/getHoroscopeOnDashboard`, {
        id: dashboardId,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate({
            pathname: '/report',
            search: `?dashboard=${dashboardId}`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [navigate, dashboardId]);

  const submitAnswer = () => {
    if (answer.join('').length === 30) {
      setQuestionSet(2);
    }
  };

  const submitAdditinalAnswer = async () => {
    if (answer.join('').length === 30) {
      if (uid) await saveVerandaAnswers();
      else if (dashboardId) await saveDashboardAnswers();
      else {
        localStorage.setItem('responseString', answer.join(''));
        navigate('/report');
      }
    }
  };

  const saveVerandaAnswers = async () => {
    await axios
      .post(BASE_API + '/userdetails/updateAnswerString', {
        uid,
        answer: answer.join(''),
      })
      .then((res) => {
        if (res.status === 200) {
          navigate({ pathname: '/report', search: `?uid=${uid}` });
        }
      })
      .catch((err) => console.log(err));
  };

  const saveDashboardAnswers = async () => {
    await axios
      .post(BASE_API + '/userdetails/saveHoroscopeOnDashboard', {
        id: dashboardId,
        answer: answer.join(''),
      })
      .then((res) => {
        if (res.status === 200) {
          navigate({
            pathname: '/report',
            search: `?dashboard=${dashboardId}`,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const sendUserUpdateToVeranda = async () => {
    await axios
      .get(
        `https://verandahighered.com/api/career-naksha-users-data-update?uid=${uid}&test_initiated=yes&report_generated=no&course_recommended=null`
      )
      .then((res) => {
        console.log(`User course details sent: ${res.data}`);
      })
      .catch((err) => console.log(err));
  };

  const userResponse = (key) => {
    if (clicked === key) {
      return 'fill';
    } else if (answer[currentQuestion] === key) {
      return 'fill';
    } else {
      return 'notfill';
    }
  };

  const userResponseSubmit = (key) => {
    const temp = [...answer];
    temp[currentQuestion] = `${key}`;
    setAnswer(temp);
    setClicked(key);
  };

  const userAdditionalResponse = (key) => {
    if (clicked === key) {
      return 'fill';
    } else if (additionalAnswer[currentAdditinalQuestion] === key) {
      return 'fill';
    } else {
      return 'notfill';
    }
  };

  const userAdditionalResponseSubmit = (key) => {
    const temp = [...additionalAnswer];
    temp[currentAdditinalQuestion] = `${key}`;
    setAdditionalAnswer(temp);
    setClicked(key);
  };

  const autoChange = () => {
    if (questionSet === 1) {
      if (currentQuestion < 29 && clicked >= 1 && clicked <= 5) {
        setClicked('');
        setCurrentQuestion(currentQuestion < 29 ? currentQuestion + 1 : 29);
      }
      if (currentQuestion === 29 && clicked >= 1 && clicked <= 5) {
        setQuestionSet(2);
      }
    } else if (questionSet === 2) {
      if (currentAdditinalQuestion < 4 && clicked >= 1 && clicked <= 2) {
        setClicked('');
        setCurrentAdditinalQuestion(
          currentAdditinalQuestion < 4 ? currentAdditinalQuestion + 1 : 5
        );
      }
    }
  };

  autoChange();

  useEffect(() => {
    getQuestions();
    if (uid) {
      getUserData();
    } else if (dashboardId) getDashboardUserData();
  }, [dashboardId, getDashboardUserData, getUserData, uid]);

  return (
    <>
      <div id="AssessmentPage">
        <div className="background">
          <img src="./img/Home/hero-2.svg" alt="" />
        </div>

        <div className="AssessmentContainer">
          <img
            src="./img/Home/asset-5.svg"
            alt="hand"
            className="asset-5"></img>
          <img
            src="./img/Home/asset-3.svg"
            alt="hand"
            className="asset-3"></img>
          <img
            src="./img/Home/asset-6.svg"
            alt="hand"
            className="asset-6"></img>

          {questionSet === 1 ? (
            <div className="questionnaire">
              <h2>
                {questions !== undefined ? questions[currentQuestion] : ''}
              </h2>
              <div className="questionnaire-answer-container">
                {/* Left arrow button */}
                <img
                  src="./img/Home/left-arrow.svg"
                  alt="<"
                  onClick={() => {
                    setClicked('');
                    setCurrentQuestion(
                      currentQuestion > 0 ? currentQuestion - 1 : 0
                    );
                  }}
                />
                <div className="questionnaire-answer">
                  <input type="radio" name="1" id="5" />
                  <label
                    htmlFor="5"
                    onClick={() => userResponseSubmit(5)}
                    className={userResponse(5)}>
                    Strongly Like
                  </label>

                  <input type="radio" name="1" id="4" />
                  <label
                    htmlFor="4"
                    onClick={() => userResponseSubmit(4)}
                    className={userResponse(4)}>
                    Like
                  </label>

                  <input type="radio" name="1" id="3" />
                  <label
                    htmlFor="3"
                    onClick={() => userResponseSubmit(3)}
                    className={userResponse(3)}>
                    Unsure
                  </label>

                  <input type="radio" name="1" id="2" />
                  <label
                    htmlFor="2"
                    onClick={() => userResponseSubmit(2)}
                    className={userResponse(2)}>
                    Dislike
                  </label>

                  <input type="radio" name="1" id="1" />
                  <label
                    htmlFor="1"
                    onClick={() => userResponseSubmit(1)}
                    className={userResponse(1)}>
                    Strongly Dislike
                  </label>
                </div>

                {currentQuestion !== 29 ? (
                  // right arrow button
                  <img
                    src="./img/Home/right-arrow.svg"
                    alt=">"
                    onClick={() => {
                      if (clicked !== '' || answer[currentQuestion] > 0) {
                        setClicked('');
                        setCurrentQuestion(
                          currentQuestion < 29 ? currentQuestion + 1 : 29
                        );
                      }
                    }}
                  />
                ) : (
                  <img
                    src="./img/Home/right-arrow.svg"
                    alt=">"
                    onClick={() => submitAnswer()}
                  />
                )}
              </div>
              <div className="questionnaire-count">
                <h3>{currentQuestion + 1} / 35</h3>
              </div>
            </div>
          ) : (
            <div className="questionnaire">
              <h2>{addtionalQuestion[currentAdditinalQuestion]}</h2>
              <div className="questionnaire-answer-container">
                <img
                  src="./img/Home/left-arrow.svg"
                  alt="<"
                  onClick={() => {
                    setClicked('');
                    setCurrentAdditinalQuestion(
                      currentAdditinalQuestion > 0
                        ? currentAdditinalQuestion - 1
                        : 0
                    );
                  }}
                />
                <div className="questionnaire-answer">
                  <input type="radio" name="1" id="1" />
                  <label
                    htmlFor="1"
                    onClick={() => userAdditionalResponseSubmit(1)}
                    className={userAdditionalResponse(1)}>
                    Yes
                  </label>
                  <input type="radio" name="1" id="2" />
                  <label
                    htmlFor="2"
                    onClick={() => userAdditionalResponseSubmit(2)}
                    className={userAdditionalResponse(2)}>
                    No
                  </label>
                </div>

                {currentAdditinalQuestion !== 4 ? (
                  <img
                    src="./img/Home/right-arrow.svg"
                    alt=">"
                    onClick={() => {
                      setClicked('');
                      setCurrentAdditinalQuestion(
                        currentAdditinalQuestion < 4
                          ? currentAdditinalQuestion + 1
                          : 5
                      );
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    id={additionalAnswer.join('').length >= 5 ? '' : 'disable'}>
                    <img
                      src="./img/Report/finish button.png"
                      alt=">"
                      onClick={() => submitAdditinalAnswer()}
                      style={{ width: '40px' }}
                    />
                  </div>
                )}
              </div>
              <div className="questionnaire-count">
                <h3>{currentAdditinalQuestion + 1 + 30} / 35</h3>
              </div>
              {currentAdditinalQuestion === 4 && (
                <div className="text-center text-xs">
                  If you cannot submit please check if you've answered previous
                  questions correctly
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssessmentPage;

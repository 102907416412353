import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import '../css/Home.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    logoutAction,
    otpVerificationAction,
    sendOTPRequestAction,
} from '../Store/UserAuthentication';

const Home = () => {
    const digit1Ref = useRef();
    const digit2Ref = useRef();
    const digit3Ref = useRef();
    const digit4Ref = useRef();
    const digit5Ref = useRef();

    const [digit1, setDigit1] = useState();
    const [digit2, setDigit2] = useState();
    const [digit3, setDigit3] = useState();
    const [digit4, setDigit4] = useState();
    const [digit5, setDigit5] = useState();

    const dispatch = useDispatch();

    const [trigger, setTrigger] = useState(false);
    const navigate = useNavigate();
    const nextVariants = {
        hidden: {
            x: '+100vw',
        },
        visible: {
            x: '0',
        },
        transition: {
            type: 'spring',
            stiffness: 120,
        },
    };

    const { userInfo } = useSelector((state) => state.user);
    
    const [page, setPage] = useState('');

    const [userPhoneNumber, setUserPhoneNumber] = useState();

    const otpHandler = (number, otp) => {
        console.log('otpHandler-otp ', otp);
        if(otp.toString().length!==5){
            alert ("Please enter 5 Digit OTP");
            return;
        }
        dispatch(otpVerificationAction(number, otp, navigate, 'assessment'));
       // navigate('/assessment');
    };
    const submitHandler = (number) => {
        if(number.toString().length!==10){
            alert ("Phone Number is invalid");
            return;
        }
        dispatch(sendOTPRequestAction(number));
        setPage('otp');
    };

    useEffect(()=>{

    },[])

    //
    //
    return (
        <div>
            {trigger && (
                <motion.div
                    className="sign"
                    variants={nextVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <div className="container">
                        <div className="text">
                            <h2>Who are we?</h2>
                            <p>
                                Everyone aspires to achieve their dream career
                                and are inquisitive to know what their future
                                beholds. In this quest we are bringing a unique
                                21st century technologically advanced (built on
                                AI/ML) assessments to predict your future.
                            </p>
                            <h2>Why trust us?</h2>
                            <p>
                                CareerNaksha leverages more than 10+ years of
                                experience in career tech space including
                                psychometrics, career counselling & coaching. We
                                measure your personality & interest using
                                multiple intelligence parameters along with
                                world’s largest career database O*NET
                            </p>
                            <div className="statics">
                                <div>
                                    <h4>1 M+</h4>
                                    <h3>Horoscope taken</h3>
                                </div>
                                <div>
                                    <h4>15k +</h4>
                                    <h3>Career counselling</h3>
                                </div>
                            </div>
                        </div>

                        <div className="form-container">
                            <div className="form">
                                <h2>Sign Up Today!</h2>
                                {page !== 'otp' ? (
                                    <div>
                                        <p>
                                            Enter your phone number to get a
                                            verification code !
                                        </p>
                                        <div>
                                            <input
                                                type="number"
                                                placeholder="Mobile Number"
                                                onChange={(e) =>
                                                    setUserPhoneNumber(
                                                        e.target.value
                                                    )
                                                }
                                                style={{
                                                    textAlign: 'center',
                                                    fontWeight: '600',
                                                    fontSize: '14px',
                                                    letterSpacing: '1px',
                                                }}
                                            />
                                            <button
                                                onClick={() =>
                                                    submitHandler(
                                                        userPhoneNumber
                                                    )
                                                }
                                            >
                                                Send OTP
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p>
                                            We’ve sent you a 5 digit code.
                                            Please enter below to verify.
                                        </p>

                                        <div>
                                            {/* <input type="text" placeholder="OTP" /> */}
                                            <input
                                                type="hidden"
                                                value={userPhoneNumber}
                                            />
                                            <div className="otp-form">
                                                <input
                                                    type="text"
                                                    maxLength={1}
                                                    value={digit1}
                                                    ref={digit1Ref}
                                                    onChange={(e) => {
                                                        setDigit1(
                                                            e.target.value
                                                        );
                                                        if (
                                                            e.target.value !==
                                                            ''
                                                        ) {
                                                            digit2Ref.current.focus();
                                                        }
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    value={digit2}
                                                    ref={digit2Ref}
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        setDigit2(
                                                            e.target.value
                                                        );
                                                        if (
                                                            e.target.value !==
                                                            ''
                                                        ) {
                                                            digit3Ref.current.focus();
                                                        }
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    value={digit3}
                                                    ref={digit3Ref}
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        setDigit3(
                                                            e.target.value
                                                        );
                                                        if (
                                                            e.target.value !==
                                                            ''
                                                        ) {
                                                            digit4Ref.current.focus();
                                                        }
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    value={digit4}
                                                    ref={digit4Ref}
                                                    maxLength={1}
                                                    onChange={(e) => {
                                                        setDigit4(
                                                            e.target.value
                                                        );
                                                        if (
                                                            e.target.value !==
                                                            ''
                                                        ) {
                                                            digit5Ref.current.focus();
                                                        }
                                                    }}
                                                />
                                                <input
                                                    type="text"
                                                    value={digit5}
                                                    ref={digit5Ref}
                                                    maxLength={1}
                                                    onChange={(e) =>
                                                        setDigit5(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <button
                                                onClick={() =>
                                                    otpHandler(
                                                        userPhoneNumber,
                                                        `${digit1}${digit2}${digit3}${digit4}${digit5}`
                                                    )
                                                }
                                            >
                                                Verify
                                            </button>
                                            <br />
                                            <button
                                                style={{
                                                    fontSize: '12px',
                                                    borderBottom: 'none',
                                                }}
                                                onClick={() => {
                                                    dispatch(
                                                        sendOTPRequestAction(
                                                            localStorage.getItem(
                                                                'number'
                                                            )
                                                        )
                                                    );
                                                    alert(
                                                        `OTP Resend on your mobile number +91${localStorage.getItem(
                                                            'number'
                                                        )}`
                                                    );
                                                }}
                                            >
                                                Resend OTP
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div className="other-action">
                                    <Link to="/assessment">Take Assesment</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sign-blur glass"></div>
                </motion.div>
            )}

            <div className="hero">
                <div className="hero-img">
                    <img
                        src="https://career-naksha.s3.ap-south-1.amazonaws.com/assets/img/Landing+page_gif+(1).gif"
                        alt=""
                    />
                    {/* <img src="./img/Home/hero.gif"></img> */}
                </div>

                {!trigger && (
                    <div className="text">
                        <h2>Predict Your Future with CareerNaksha™</h2>
                    </div>
                )}

                {!userInfo ? (
                    <div
                        className="user-action"
                        onClick={() => {
                            if (window.screen.width > 600) {
                                setTrigger(true);
                            } else {
                                navigate('/login');
                            }
                        }}
                    >
                        <h2>Login</h2>
                    </div>
                ) : (
                    <div
                        className="user-action"
                        onClick={() => dispatch(logoutAction())}
                    >
                        <h2>Logout</h2>
                    </div>
                )}

                {!trigger && (
                    <Link to="/assessment">
                        <p className="take-assessment">Take Horoscope</p>
                    </Link>
                )}
            </div>

            <div className="section-2">
                <div className="text">
                    <h2>“</h2>
                    <p>
                        I was feeling stuck in my career and was totally
                        confused what to do next. After taking this “Career
                        Horoscope” I am feeling confident to pursue my dream
                        career with recommended upskilling .
                    </p>
                    <h3>
                        – Prakash Tiwary, VP Product & Engineering at Saleskem{' '}
                    </h3>
                </div>
                <div className="testomonial"></div>
                <img src="./img/Home/man.png" alt="avatar" />
                <div className="avatar">
                    {window.screen.width > 400 ? (
                        <img
                            className="avatar-1"
                            src="./img/Home/asset-1.svg"
                            alt="avatar"
                        />
                    ) : (
                        <img
                            className="avatar-1"
                            src="./img/Home/asset-1-left-hand.svg"
                            alt="avatar"
                        />
                    )}
                    <img
                        className="avatar-2"
                        src="./img/Home/asset-2.svg"
                        alt="avatar"
                    />
                    <img
                        className="avatar-3"
                        src="./img/Home/asset-3.svg"
                        alt="avatar"
                    />
                    <img
                        className="avatar-4"
                        src="./img/Home/asset-4.svg"
                        alt="avatar"
                    />
                </div>
            </div>

            <div className="section-3">
                <div>
                    <h2>
                        Career Horoscope is a modern way to know your future
                        career path. It will not only answer your pressing
                        questions but will give you a logical direction towards
                        your dream career.
                    </h2>
                    <div className="buttons">
                        {!userInfo ? (
                            window.screen.width > 440 ? (
                                <Link
                                    to=""
                                    onClick={() => {
                                        window.scroll({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                        setTrigger(true);
                                    }}
                                >
                                    Sign Up Today!
                                </Link>
                            ) : (
                                <Link to="/login">Sign Up Today!</Link>
                            )
                        ) : (
                            <Link to="/payment">Talk to Career Coach</Link>
                        )}
                        <Link to="/assessment">Take Your Test</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

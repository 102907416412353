import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Payment = () => {
  useEffect(() => {
    const rzpPaymentForm = document.getElementById('rzp_payment_form');

    //   const rzpPaymentForm1 = document.getElementById("rzp_payment_form_1");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
      script.async = true;
      script.dataset.payment_button_id = 'pl_LBrHGTyW5HXUoE';
      rzpPaymentForm.appendChild(script);
    }

    // if (!rzpPaymentForm1.hasChildNodes()) {
    //     const script = document.createElement("script");
    //     script.src="https://checkout.razorpay.com/v1/payment-button.js";
    //     script.async=true;
    //     script.dataset.payment_button_id="pl_L4maaiJOwSAyE9";
    //     rzpPaymentForm.appendChild(script);
    // }
  }, []);

  const clickbtn = () => {};

  const screen = window.screen.width;
  const [currentPackage, setCurrentPackage] = useState(1);

  const plan1 = [
    'Personalized detailed assessment & detailed report',
    'Detailed description of the best career pathways for you',
    'Top 10 fit recommendations for your career roles',
    'A free 15min session with our career coach to know your report',
    'Opportunity to speak to our expert & opt for next plan',
  ];
  const plan2 = [
    'Personalized detailed career coaching session with expert',
    'Resolve all your queries & doubts speaking to our expert',
    'Understand your career horoscope report with them',
    'Opportunity to opt for detailed career planning & report',
    'Counselling one to one for your dream career',
  ];
  const plan3 = [
    'Personalized detailed assessment & detailed report',
    'Top 10 fit recommendations for your career roles',
    'Personalized detailed career coaching session with expert',
    'Resolve all your queries & doubts speaking to our expert',
    'Understand your career horoscope report with them',
  ];

  return (
    <div className="payment">
      <div className="back-btn">
        <Link to="/report">
          <button>Back</button>
        </Link>
      </div>

      {/* <div className="background payment-background">
                <img
                    src="./img/Payment/CN_brief report_Hero banner 1.png"
                    alt=""
                />
            </div> */}
      <div>
        <div className="payment-page-flag">
          <h4>Plans</h4>
        </div>
        <div className="packages">
          <div className="mobile-package-tabs">
            <p onClick={() => setCurrentPackage(1)}>Solar</p>
            <p onClick={() => setCurrentPackage(2)}>Galaxy</p>
            <p onClick={() => setCurrentPackage(3)}>Universe</p>
          </div>
          <div
            className="package"
            id={currentPackage !== 1 ? 'hide-package' : ''}>
            <div className="package-name">
              <h4>Plan Solar</h4>
            </div>
            <div className="package-detail">
              <h2>Powers - Detailed Assessment + Report</h2>
              {plan1.map((n) => (
                <p>
                  <span>
                    <img src="./img/Payment/bullet.png" alt="-" />
                  </span>
                  {n}
                </p>
              ))}
            </div>
            <div className="package-action">
              <h4 style={{ display: 'block', textAlign: 'center' }}>
                ₹ 499.00{' '}
              </h4>
              {/* <div className="button">
                                <span>buy now</span>
                                <form  style={{visibility: 'hidden', textAlign:'center',marginTop:'10px',backgroundColor:'transparent' , marginBottom:'10px'}} id='rzp_payment_form' >
                                </form>
                            </div>
                            <form  style={{textAlign:'center',marginTop:'10px',backgroundColor:'transparent' , marginBottom:'10px'}} id='rzp_payment_form' >
                            </form> */}

              <div className="payment-page-btn">
                <form id="rzp_payment_form"></form>
              </div>
            </div>
          </div>

          <div
            className="package"
            id={currentPackage !== 2 ? 'hide-package' : ''}>
            <div className="package-name">
              <h4>Plan Galaxy</h4>
            </div>
            <div className="package-detail">
              <h2>Powers - Career Coaching + Talk to Expert</h2>
              {plan2.map((n) => (
                <p>
                  <span>
                    <img src="./img/Payment/bullet.png" alt="-" />
                  </span>
                  {n}
                </p>
              ))}
            </div>
            <div className="package-action">
              <h4>₹ 999.00 </h4>
              <Link to="/plan2">
                <button>Buy Now</button>
              </Link>
            </div>
          </div>

          <div
            className="package"
            id={currentPackage !== 3 ? 'hide-package' : ''}>
            <div className="package-name">
              <h4>Plan Universe</h4>
            </div>
            <div className="package-detail">
              <h2>Powers - Detailed Assessment + Expert Coaching</h2>
              {plan3.map((n) => (
                <p>
                  <span>
                    <img src="./img/Payment/bullet.png" alt="-" />
                  </span>
                  {n}
                </p>
              ))}
            </div>
            <div className="package-action">
              <h4>₹ 1,499.00 </h4>
              <Link to="/plan3">
                <button>Buy Now</button>
              </Link>
            </div>
          </div>
        </div>
        <h4 className="tag-line">
          You are just a click away from a better successful future !
        </h4>
      </div>
    </div>
  );
};

export default Payment;

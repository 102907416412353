import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

// Reducer Import
import { userAuthenticationReducer } from "./UserAuthentication";
import { userAssessmentReportReducer } from "./Assessment";

const reducer = combineReducers({
	user: userAuthenticationReducer,
	report: userAssessmentReportReducer,
});

const reportFromLocalStorage = localStorage.getItem("report")
	? JSON.parse(localStorage.getItem("report"))
	: [];

	
const userFromLocalStorage = localStorage.getItem("user")
	? { userInfo: JSON.parse(localStorage.getItem("user")) }
	: [];

const initialState = {
	report: { report: reportFromLocalStorage },
	user: userFromLocalStorage,
};

const middleware = [thunk];

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// *Component
import App from './App';

// *redux
import { Provider } from 'react-redux';
import store from './Store/Store.js';

// *Style Sheet
import './css/index.css';
import './css/report-sign.css';
import './css/payment.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

import FormControl from '@mui/material/FormControl';
import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import 'react-calendar/dist/Calendar.css';
import { useSelector } from 'react-redux';
import MyCalendar from 'react-calendar';
import Box from '@mui/material/Box';
import axios from 'axios';
import '../../css/Plans.css';

const BASE_API ='https://api.careernaksha.com';

//const BASE_API ='http://localhost:3000';


const Plan3 = () => {
    const [isActive2, setIsActive2] = useState(Array(5).fill(false));
    const [isActive, setIsActive] = useState(Array(14).fill(false));
    const { userInfo } = useSelector((state) => state.user);
    const [counsellor, setCounsellor] = useState(false);
    const [dateTime, setDateTime] = useState(false);
    const [value, onChange] = useState(new Date());
    const [age, setAge] = React.useState('');
    const [grade, setGrade] = useState('E');
    const [flag, setFlag] = useState(false);
    const [email, setEmail] = useState('');
    const [data, setData] = useState([]);
    const [time, setTime] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const day = value.toLocaleString('en-us', { weekday: 'long' });
    const date = value.getDate();
    const month = value.toLocaleString('default', { month: 'long' });
    const final_date = `${day}, ${date} ${month}`;

    const handleChange = (e) => {
        setAge(e.target.value);
        if (e.target.value === '8 & below') {
            setGrade('A');
        }  else if (e.target.value === '10') {
            setGrade('B');
        } else if (e.target.value === '11 & 12') {
            setGrade('C');
        } else if (e.target.value === 'graduate') {
            setGrade('D');
        } else {
            setGrade('E');
        }
    };

    function loadRazorpay() {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';

        script.onerror = () => {
            alert('Razorpay SDK Failed To Load Are You Online ?');
        };

        script.onload = async () => {
            try {
                const result = await axios.post(
                    BASE_API+'/api/communityPsychometrics/create-order',
                    {
                        amount: 1499 + '00',
                    }
                );

                const { amount, id: order_id, currency } = result.data;
                let uuid;
                let d = new Date();
                uuid = d.valueOf();
                const options = {
                    key: 'rzp_live_LaM5pgz5HtEDdv',
                    amount: amount.toString(),
                    currency: currency,
                    name: 'Plan Solar',
                    description: 'Detailed Assessment + Report',
                    order_id: order_id,
                    handler: async function (response) {
                        const result = await axios.post(
                            BASE_API+'/api/horoscopePsychometrics/detailed-test',
                            {
                                amount: amount,
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                                uuid: uuid,
                                email: email,
                                phone: userInfo.data.number,
                                primary: grade,
                            }
                        );

                        const result2 = await axios.post(
                            BASE_API+'/api/horoscopePsychometrics/mail-appt',
                            {
                                email: email,
                                date: final_date,
                                time: time,
                                name: name,
                            }
                        );

                        navigate('/thankyou');
                    },
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            } catch (error) {
                alert(error);
            }
        };

        document.body.appendChild(script);
    }

    const toggleClass = (index, time) => {
        if (dateTime === false) {
            setTime(time);
            const noActiveClasses = Array(14).fill(false);
            const activeClasses = [
                noActiveClasses.slice(0, index),
                !isActive[index],
                noActiveClasses.slice(index + 1),
            ].flat();
            setIsActive(activeClasses);
            setDateTime(true);
        } else {
            setTime('');
            const noActiveClasses = Array(14).fill(false);
            setIsActive(noActiveClasses);
            setDateTime(false);
        }
    };

    const toggleClass2 = (index, name) => {
        if (counsellor === false) {
            setName(name);
            const noActiveClasses2 = Array(5).fill(false);
            const activeClasses2 = [
                noActiveClasses2.slice(0, index),
                !isActive2[index],
                noActiveClasses2.slice(index + 1),
            ].flat();
            setIsActive2(activeClasses2);
            setCounsellor(true);
        } else {
            setName('');
            const noActiveClasses2 = Array(5).fill(false);
            setIsActive2(noActiveClasses2);
            setCounsellor(false);
        }
    };

    useEffect(() => {

        const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {
            const script = document.createElement("script");
            script.src="https://checkout.razorpay.com/v1/payment-button.js";
            script.async=true;
            script.dataset.payment_button_id="pl_L56yp01eVvxY6v";
            rzpPaymentForm.appendChild(script);
        }
        async function getCounsellorsData() {
            try {
                const response = await axios.get(
                    'https://dashboard.careernaksha.com/career-gurus'
                );

                const data = Object.values(
                    response.data.map((item, index) => {
                        const { Name, City } = item;
                        return { Name, City };
                    })
                );

                let userNames = [];
                for (let i = 0; i < 5; i++) {
                    userNames = [
                        ...userNames,
                        data[Math.floor(Math.random() * data.length)],
                    ];
                }
                setData(userNames);
            } catch (error) {
                console.log(error);
            }
        }
        getCounsellorsData();
    }, []);

    return (
        <div className="calendar-container">
            <h1 className="heading-calendar">
                <div className="left-text">Select a Date and Time</div>
                <div className="right-text">Booking Summary</div>
            </h1>
            <Divider className="divider" />
            <br />
            <br />
            <div className="calendar">
                <div className="grid-1">
                    <MyCalendar onChange={onChange} value={value} />
                </div>
                <div>
                    <div className="grid-2-outer">
                        <p
                            className="date-text-1"
                            style={{
                                lineHeight: '150%',
                                textAlign: 'center',
                            }}
                        >
                            {final_date} Indian Standard Time (IST)
                        </p>
                        <div className="grid-2">
                            <p
                                onClick={() => toggleClass(0, '11:00 am')}
                                className={
                                    isActive[0] ? 'active-text' : 'simple-text'
                                }
                            >
                                11:00 am
                            </p>
                            <p
                                onClick={() => toggleClass(1, '11:30 am')}
                                className={
                                    isActive[1] ? 'active-text' : 'simple-text'
                                }
                            >
                                11:30 am
                            </p>
                            <p
                                onClick={() => toggleClass(2, '12:00 pm')}
                                className={
                                    isActive[2] ? 'active-text' : 'simple-text'
                                }
                            >
                                12:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(3, '12:30 pm')}
                                className={
                                    isActive[3] ? 'active-text' : 'simple-text'
                                }
                            >
                                12:30 pm
                            </p>
                            <p
                                onClick={() => toggleClass(4, '1:00 pm')}
                                className={
                                    isActive[4] ? 'active-text' : 'simple-text'
                                }
                            >
                                1:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(5, '1:30 pm')}
                                className={
                                    isActive[5] ? 'active-text' : 'simple-text'
                                }
                            >
                                1:30 pm
                            </p>
                            <p
                                onClick={() => toggleClass(6, '2:00 pm')}
                                className={
                                    isActive[6] ? 'active-text' : 'simple-text'
                                }
                            >
                                2:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(7, '2:30 pm')}
                                className={
                                    isActive[7] ? 'active-text' : 'simple-text'
                                }
                            >
                                2:30 pm
                            </p>
                            <p
                                onClick={() => toggleClass(8, '3:00 pm')}
                                className={
                                    isActive[8] ? 'active-text' : 'simple-text'
                                }
                            >
                                3:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(9, '3:30 pm')}
                                className={
                                    isActive[9] ? 'active-text' : 'simple-text'
                                }
                            >
                                3:30 pm
                            </p>
                            <p
                                onClick={() => toggleClass(10, '4:00 pm')}
                                className={
                                    isActive[10] ? 'active-text' : 'simple-text'
                                }
                            >
                                4:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(11, '4:30 pm')}
                                className={
                                    isActive[11] ? 'active-text' : 'simple-text'
                                }
                            >
                                4:30 pm
                            </p>
                            <p
                                onClick={() => toggleClass(12, '5:00 pm')}
                                className={
                                    isActive[12] ? 'active-text' : 'simple-text'
                                }
                            >
                                5:00 pm
                            </p>
                            <p
                                onClick={() => toggleClass(13, '5:30 pm')}
                                className={
                                    isActive[13] ? 'active-text' : 'simple-text'
                                }
                            >
                                5:30 pm
                            </p>
                        </div>
                    </div>
                </div>
                <div className="grid-3">
                    <h1 className="heading-simple-text">
                        Detailed Test/Report
                    </h1>
                    <TextField
                        className="hello"
                        id="email"
                        label="Enter Email"
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <Box sx={{ minWidth: 120 }} style={{ margin: '1rem 0' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Category
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value="professional">
                                    Working Professional
                                </MenuItem>
                                <MenuItem value="graduate">
                                    Graduate UG/PG
                                </MenuItem>
                                <MenuItem value="11 & 12">
                                    Class 11th & 12th
                                </MenuItem>
                                <MenuItem value="10">Class 9th and 10th</MenuItem>
                                <MenuItem value="8 & below">
                                    Class 8th & Below
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <br />

                    <h1 className="heading-simple-text">Counsellor</h1>

                    {data.map((d, index) => {
                        return (
                            <div
                                onClick={() => toggleClass2(index, d.Name)}
                                className={
                                    isActive2[index]
                                        ? 'active-text-2 counsellor-detail'
                                        : 'simple-text-2 counsellor-detail'
                                }
                                key={index}
                            >
                                <p style={{ marginBottom: '.25rem' }}>
                                    {d.Name}
                                </p>
                                <p>{d.City}</p>
                            </div>
                        );
                    })}
                    <h1 className="booking-text-plan2">
                        Detailed Test/Report + Career Counselling
                    </h1>
                    <form id='rzp_payment_form' style={{textAlign:'center',marginTop:'10px',marginBottom:'10px'}}>


</form>
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        onClick={() => navigate('/payment')}
                        style={{ margin: '1rem 0 2rem' }}
                    >
                        Back
                    </Button>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Plan3;

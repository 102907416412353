import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import ReportSignIn from './pages/component/ReportSignIn';
import AssessmentPage from './pages/AssessmentPage';
import Login from './pages/component/Login';
import Plan1 from './pages/component/Plan1';
import Plan2 from './pages/component/Plan2';
import Plan3 from './pages/component/Plan3';
import ThankYou from './pages/component/ThankYou';
import Payment from './pages/Payment';
import Report from './pages/Report';
import Slide from './pages/Slide';
import Home from './pages/Home';

const App = () => {
  const { userInfo } = useSelector((state) => state.user);
  return (
    <AnimatePresence exitBeforeEnter>
      <div className="container">
        <Routes>
          <Route path="/plan-solar" element={<Plan1 />} exact />
          {userInfo && (
            <>
              <Route path="/plan2" element={<Plan2 />} exact />
              <Route path="/plan3" element={<Plan3 />} exact />
            </>
          )}
          <Route path="/" element={<Home />} exact />
          <Route path="/report/veranda" element={<Report />} exact />
          <Route path="/login" element={<Login />} exact />
          <Route path="/assessment" element={<AssessmentPage />} exact />
          <Route path="/report" element={<Report />} exact />
          <Route path="/report/sign-in" element={<ReportSignIn />} exact />
          <Route path="/slide" element={<Slide />} exact />
          <Route path="/payment" element={<Payment />} exact />
          <Route path="/thankyou" element={<ThankYou />} exact />
          <Route path="*" element={<Navigate to={'/'} />} exact />
        </Routes>
      </div>
    </AnimatePresence>
  );
};

export default App;

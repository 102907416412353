import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
    return (
        <div>
            <div className="text-thankyou">
                <h1>Thank You!</h1>
                <h2> Our Team will get in touch soon!</h2>
            </div>

            <div className="payment">
                <div className="back-btn">
                    <Link to="/report">
                        <button>Back</button>
                    </Link>
                </div>

                <div className="background">
                    <img src="../img/Report/Hero_bannerbackground.png" alt="" />
                </div>
            </div>
        </div>
    );
};

export default ThankYou;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  sendOTPRequestAction,
  otpVerificationAction,
} from '../../../Store/UserAuthentication';
import { userAssessmentReportReducer } from '../../../Store/Assessment';
import '../../../css/login.css';

const SubLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.user);
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const [enableButton, setEnableButton] = useState(false);

  const [page, setPage] = useState('');

  const [digit1, setDigit1] = useState();
  const [digit2, setDigit2] = useState();
  const [digit3, setDigit3] = useState();
  const [digit4, setDigit4] = useState();
  const [digit5, setDigit5] = useState();

  const digit1Ref = useRef();
  const digit2Ref = useRef();
  const digit3Ref = useRef();
  const digit4Ref = useRef();
  const digit5Ref = useRef();

  useEffect(() => {
    let url = window.location.href;
    let data = url.split('://');
    let protocol = data[0];
    console.log(protocol);
    if (
      window.location.href ==
      'https://horoscope.careernaksha.com/report/veranda'
    ) {
      dispatch(
        userAssessmentReportReducer({
          type: 'USER_ONETIME_CONFIRMATION_SUCCESS',
          payload: false,
        })
      );
      navigate('/report');
    }
  }, []);

  const otpHandler = (number, otp) => {
    console.log('otpHandler-otp ', otp);
    if (otp.toString().length !== 5) {
      alert('Please enter 5 Digit OTP');
      return;
    }
    // `${digit1}${digit2}${digit3}${digit4}${digit5}`
    dispatch(otpVerificationAction(number, otp, navigate, 'report')).then(
      () => {
        // if (userInfo) {
        //     navigate('/report');
        // }
      }
    );
  };
  const submitHandler = (number) => {
    if (number.toString().length !== 10) {
      alert('Phone Number is invalid');
      return;
    }
    dispatch(sendOTPRequestAction(number));
    setPage('otp');
  };

  const screen = window.screen.width;
  return (
    <>
      {screen >= 480 ? (
        <div className="sign-form">
          <div className="login-container">
            {page !== 'otp' ? (
              <div className="login-form">
                <h2>Sign Up Today!</h2>
                <p>Enter your phone number to get a verification code</p>
                <div className="form">
                  <h3>+91</h3>
                  <input
                    type="number"
                    maxLength={10}
                    onChange={(e) => setUserPhoneNumber(e.target.value)}
                  />
                </div>
                <button
                  className="btn-primary"
                  onClick={() => submitHandler(userPhoneNumber)}>
                  Request OTP
                </button>
              </div>
            ) : (
              <div className="otp-verification">
                <div className="login-form">
                  <h2>Hello Dreamer!</h2>
                  <p>
                    We have sent you a 5 digit code. Please enter the digits to
                    verify!
                  </p>
                  <input type="hidden" value={userPhoneNumber} />
                  <div className="otp-form">
                    <input
                      type="text"
                      maxLength={1}
                      value={digit1}
                      ref={digit1Ref}
                      onChange={(e) => {
                        setDigit1(e.target.value);
                        if (e.target.value !== '') {
                          digit2Ref.current.focus();
                        }
                      }}
                    />
                    <input
                      type="text"
                      value={digit2}
                      ref={digit2Ref}
                      maxLength={1}
                      onChange={(e) => {
                        setDigit2(e.target.value);
                        if (e.target.value !== '') {
                          digit3Ref.current.focus();
                        }
                      }}
                    />
                    <input
                      type="text"
                      value={digit3}
                      ref={digit3Ref}
                      maxLength={1}
                      onChange={(e) => {
                        setDigit3(e.target.value);
                        if (e.target.value !== '') {
                          digit4Ref.current.focus();
                        }
                      }}
                    />
                    <input
                      type="text"
                      value={digit4}
                      ref={digit4Ref}
                      maxLength={1}
                      onChange={(e) => {
                        setDigit4(e.target.value);
                        if (e.target.value !== '') {
                          digit5Ref.current.focus();
                        }
                      }}
                    />
                    <input
                      type="text"
                      value={digit5}
                      ref={digit5Ref}
                      maxLength={1}
                      onChange={(e) => {
                        setDigit5(e.target.value);
                        if (digit1 && digit2 && digit3 && digit4 && digit5) {
                          setEnableButton(true);
                        }
                      }}
                    />
                  </div>
                  <button
                    className="btn-primary"
                    onClick={() => {
                      dispatch(
                        sendOTPRequestAction(localStorage.getItem('number'))
                      );
                      alert(
                        `OTP Resend on your mobile number +91${localStorage.getItem(
                          'number'
                        )}`
                      );
                    }}>
                    Resend OTP
                  </button>
                  <button
                    className="btn-secondary"
                    style={{
                      marginTop: '1rem',
                    }}
                    onClick={() =>
                      otpHandler(
                        userPhoneNumber,
                        `${digit1}${digit2}${digit3}${digit4}${digit5}`
                      )
                    }>
                    Verify OTP
                  </button>
                </div>
              </div>
            )}
            {page === 'verified' ? <p>Verified</p> : ''}
          </div>
        </div>
      ) : (
        <div className="login-container loginContainer">
          {page !== 'otp' ? (
            <div className="login-form loginForm">
              <h2>Hello Dreamer!</h2>
              <p>Enter your phone number to get a verification code</p>
              <div className="form">
                <h3>+91</h3>
                <input
                  type="number"
                  maxLength={10}
                  onChange={(e) => setUserPhoneNumber(e.target.value)}
                />
              </div>
              <button
                className="btn-primary"
                onClick={() => submitHandler(userPhoneNumber)}>
                Request OTP
              </button>
            </div>
          ) : (
            <div className="otp-verification">
              <div className="login-form">
                <h2>Hello Dreamer!</h2>
                <p>
                  We have sent you a 5 digit code. Please enter the digits to
                  verify!
                </p>
                <input type="hidden" value={userPhoneNumber} />
                <div className="otp-form">
                  <input
                    type="text"
                    maxLength={1}
                    value={digit1}
                    ref={digit1Ref}
                    onChange={(e) => {
                      setDigit1(e.target.value);
                      if (e.target.value !== '') {
                        digit2Ref.current.focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    value={digit2}
                    ref={digit2Ref}
                    maxLength={1}
                    onChange={(e) => {
                      setDigit2(e.target.value);
                      if (e.target.value !== '') {
                        digit3Ref.current.focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    value={digit3}
                    ref={digit3Ref}
                    maxLength={1}
                    onChange={(e) => {
                      setDigit3(e.target.value);
                      if (e.target.value !== '') {
                        digit4Ref.current.focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    value={digit4}
                    ref={digit4Ref}
                    maxLength={1}
                    onChange={(e) => {
                      setDigit4(e.target.value);
                      if (e.target.value !== '') {
                        digit5Ref.current.focus();
                      }
                    }}
                  />
                  <input
                    type="text"
                    value={digit5}
                    ref={digit5Ref}
                    maxLength={1}
                    onChange={(e) => setDigit5(e.target.value)}
                  />
                </div>
                <button
                  className="btn-primary"
                  onClick={() =>
                    otpHandler(
                      userPhoneNumber,
                      `${digit1}${digit2}${digit3}${digit4}${digit5}`
                    )
                  }>
                  Verify OTP
                </button>
                <button
                  className="btn-secondary"
                  onClick={() => {
                    dispatch(
                      sendOTPRequestAction(localStorage.getItem('number'))
                    );
                    alert(
                      `OTP Resend on your mobile number +91${localStorage.getItem(
                        'number'
                      )}`
                    );
                  }}>
                  Resend OTP
                </button>
              </div>
            </div>
          )}
          {page === 'verified' ? <p>Verified</p> : ''}
        </div>
      )}
    </>
  );
};

export default SubLogin;

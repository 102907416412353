import axios from 'axios';

// ! CONSTANTS
const OTP_REQUEST_INITIATE = 'OTP_REQUEST_INITIATE';
const OTP_REQUEST_SUCCESS = 'OTP_REQUEST_SUCCESS';
const OTP_REQUEST_FAIL = 'OTP_REQUEST_FAIL';

const OTP_VERIFICATION_INITIATE = 'OTP_VERIFICATION_INITIATE';
const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
const OTP_VERIFICATION_FAIL = 'OTP_VERIFICATION_FAIL';

const USER_LOGOUT = 'USER_LOGOUT';

const BASE_API = 'https://api.careernaksha.com';

//const BASE_API ='http://localhost:3000';

//  ! REDUCER

export const userAuthenticationReducer = (state = {}, action) => {
    switch (action.type) {
        // ! OTP SENDER REDUCER

        case OTP_REQUEST_INITIATE:
            return { loding: true };
        case OTP_REQUEST_SUCCESS:
            return { loding: false, otp_send_msg: action.payload };
        case OTP_REQUEST_FAIL:
            return { loding: false, error: action.payload };

        // ! OTP VERIFICATION REDUCER

        case OTP_VERIFICATION_INITIATE:
            return { loding: true, userInfo: {} };
        case OTP_VERIFICATION_SUCCESS:
            return { loding: false, userInfo: action.payload };
        case OTP_VERIFICATION_FAIL:
            return { loding: false, error: action.payload };

        // !
        case USER_LOGOUT:
            return { loding: true, userInfo: { token: 'logout' } };

        default:
            return state;
    }
};

//  ! ACTIONS

//  ! OTP SENDER ACTION

export const sendOTPRequestAction = (number) => async (dispatch) => {
    try {
        dispatch({ type: OTP_REQUEST_INITIATE });
        localStorage.setItem('number', number);
        const { data } = await axios.post(
            BASE_API + '/api/horoscope/user/signup',
            {
                number: number,
            }
        );
        dispatch({ type: OTP_REQUEST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: OTP_REQUEST_FAIL, payload: error.message });
    }
};

//  ! OTP VERIFICATION ACTION

export const otpVerificationAction =
    (number, otp, navigate, nextPage) => async (dispatch) => {
        try {
            dispatch({ type: OTP_VERIFICATION_INITIATE });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            const body = {
                number: number,
                otp: otp,
            };
            //const { data }
            const otpResponse = await axios.post(
                BASE_API + '/api/horoscope/user/signup/verify',
                JSON.stringify(body),
                config
            );
            if (otpResponse.status !== 200) {
                alert('OTP Verification failed');
                dispatch({
                    type: OTP_VERIFICATION_FAIL,
                    payload: 'OTP Verification failed',
                });
                return;
            }

            console.log(
                'GETTING VERIFICATION' + JSON.stringify(otpResponse.data)
            );

            try {
                const res = await axios.post(
                    BASE_API + '/api/horoscope/user/sendEmail',
                    { phone: number },
                    config
                );
            } catch (error) {
                console.log(`email trigger didn't work`);
            }

            localStorage.setItem('user', JSON.stringify(otpResponse.data));
            localStorage.setItem('userId', otpResponse.data['data']._id);

            if (
                number == 8469149288 ||
                number == 7990148919 ||
                number == 8795675599 ||
                number == 9727716878
            ) {
                localStorage.setItem('tester', true);
            }

            dispatch({
                type: OTP_VERIFICATION_SUCCESS,
                payload: otpResponse.data,
            });

            if (!nextPage) {
                window.location.reload(true);
            } else {
                navigate('/' + nextPage);
            }
        } catch (error) {
            alert('OTP Verification failed');
            dispatch({ type: OTP_VERIFICATION_FAIL, payload: error.message });
        }
    };

export const logoutAction = () => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGOUT });
        localStorage.removeItem('user');
        window.location.reload(true);
    } catch (error) {
        console.log('Logout Error');
    }
};

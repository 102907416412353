import axios from 'axios';

// ! CONSTANTS

const STORE_ASSESSMENT_INITIATE = 'STORE_ASSESSMENT_INITIATE';
const STORE_ASSESSMENT_SUCCESS = 'STORE_ASSESSMENT_SUCCESS';
const STORE_ASSESSMENT_FAIL = 'STORE_ASSESSMENT_FAIL';

const ASSESSMENT_REPORT_INITIATE = 'ASSESSMENT_REPORT_INITIATE';
const ASSESSMENT_REPORT_SUCCESS = 'ASSESSMENT_REPORT_SUCCESS';
const ASSESSMENT_REPORT_FAIL = 'ASSESSMENT_REPORT_FAIL';

const USER_ONETIME_CONFIRMATION_INITIATE = 'USER_ONETIME_CONFIRMATION_INITIATE';
const USER_ONETIME_CONFIRMATION_SUCCESS = 'USER_ONETIME_CONFIRMATION_SUCCESS';
const USER_ONETIME_CONFIRMATION_FAIL = 'USER_ONETIME_CONFIRMATION_FAIL';

const BASE_API = 'https://api.careernaksha.com';

// const BASE_API = 'http://localhost:3000';

//  ! REDUCER

export const userAssessmentReportReducer = (
  state = { userOneTimeAssessment: null, userRegister: null, report: {} },
  action
) => {
  switch (action.type) {
    case USER_ONETIME_CONFIRMATION_INITIATE:
      return { ...state, loding: true, userOneTimeAssessment: 'wait' };
    case USER_ONETIME_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loding: false,
        userOneTimeAssessment: false,
      };
    case USER_ONETIME_CONFIRMATION_FAIL:
      return { ...state, loding: false, error: action.payload };

    case STORE_ASSESSMENT_INITIATE:
      return { ...state, loding: true };
    case STORE_ASSESSMENT_SUCCESS:
      return { ...state, loding: false, userRegister: action.payload };
    case STORE_ASSESSMENT_FAIL:
      return { ...state, loding: false, error: action.payload };

    case ASSESSMENT_REPORT_INITIATE:
      return { ...state, loding: true };
    case ASSESSMENT_REPORT_SUCCESS:
      return { ...state, loding: false, report: action.payload };
    case ASSESSMENT_REPORT_FAIL:
      return { ...state, loding: false, error: action.payload };

    default:
      return state;
  }
};

const leadAPI = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'user-token': 'edureka',
      },
    };

    let num = localStorage.getItem('number');

    let recommendedCourseId = localStorage.getItem('recommendedCourseId');
    let course = localStorage.getItem('course');

    if (!course) {
      course = localStorage.getItem('course');
    }
    if (!recommendedCourseId) {
      course = localStorage.getItem('recommendedCourseId');
    }

    const body = {
      phone: num,
      course: course,
      courseId: recommendedCourseId,
    };
    // const body = {
    //     "firstName":"Career",
    //     "lastName": "Naksha",
    //     "email":"test@test.com",
    //     "phone": num,
    //     "courses": courses,
    //     "country":"IN"
    // }

    // const { data } = await axios.post(
    //     'https://api-user.edureka.co/userlead/create-user-lead',
    //     JSON.stringify(body),
    //     config
    // );
    const { data } = await axios.post(
      BASE_API + '/assessment/edureka-sync',
      JSON.stringify(body),
      config
    );
    // https://api.careernaksha.com/assessment
    console.log(data, '+++');
    localStorage.setItem('leadsend', true);
  } catch (error) {
    console.error(error, 'edureka +++');
  }
};

//  ! ACTIONS

export const userAssessmentReportAction =
  (userId, answer, tester, setReportFetched) => async (dispatch) => {
    try {
      dispatch({ type: USER_ONETIME_CONFIRMATION_INITIATE });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let userID = localStorage.getItem('userId');

      const body = {
        id: userID,
      };

      let data;

      if (!tester) {
        await axios
          .post(BASE_API + '/api/horoscope/user/checkTest', body, config)
          .then((res) => {
            data = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (data?.['test'] != true || tester) {
        dispatch(storeAssessmentAction(userId, answer, config, tester))
          .then(() => {
            if (tester) {
              dispatch(
                userReportAction(
                  userId,
                  answer,
                  config,
                  tester,
                  setReportFetched
                )
              );
            } else if (!localStorage.getItem('report')) {
              dispatch(userReportAction(userId, answer, config));
            } else return true;
          })
          .then(() =>
            dispatch({
              type: USER_ONETIME_CONFIRMATION_SUCCESS,
              payload: tester ? false : data['test'],
            })
          );
      } else if (data['test'] == true) {
        dispatch({
          type: USER_ONETIME_CONFIRMATION_SUCCESS,
          payload: data['test'],
        });
      }
    } catch (error) {
      dispatch({
        type: USER_ONETIME_CONFIRMATION_FAIL,
        payload: error.message,
      });
    }
  };

export const storeAssessmentAction =
  (userId, answer, config, tester) => async (dispatch) => {
    try {
      dispatch({ type: STORE_ASSESSMENT_INITIATE });

      if (!tester) {
        const body = {
          id: userId,
          answerString: answer,
        };

        const { data } = await axios.post(
          BASE_API + '/api/horoscope/user/storeAssessment',
          body,
          config
        );
      }

      dispatch({ type: STORE_ASSESSMENT_SUCCESS, payload: true });
    } catch (error) {
      dispatch({ type: ASSESSMENT_REPORT_FAIL, payload: error.message });
    }
  };

export const userReportAction =
  (userId, answer, config, tester, setReportFetched) => async (dispatch) => {
    try {
      dispatch({ type: ASSESSMENT_REPORT_INITIATE });

      const body = {
        answerString: answer,
      };

      const { data } = await axios.post(
        BASE_API + '/assessment/getAllResults',
        body,
        config
      );

      const riasec = [
        {
          Realistic: [
            'Realistic',
            'Practical, Scientific & Methodical',
            'DOER',
            'You are competitive, straightforward and assertive, interested in activities of skill & strength.',
          ],
        },
        {
          Investigative: [
            'Investigative',
            'Observational, Analytical & Evaluate',
            'THINKER',
            'You think and observe, interested in organizing & understanding information.',
          ],
        },
        {
          Artistic: [
            'Artistic',
            'Innovative, Intuitive & Creative',
            'CREATOR',
            'You are creative, open minded, independent, original & emotional.',
          ],
        },
        {
          Social: [
            'Social',
            'Enlighten, Train & Inform Others',
            'HELPER',
            'You like to work with people and satisfy their needs by teaching, helping & building relationships.',
          ],
        },
        {
          Enterprising: [
            'Enterprising',
            'Influencer, Persuasive & Performer',
            'LEADER',
            'You are a good talker, can lead and persuade others.',
          ],
        },
        {
          Conventional: [
            'Conventional',
            'Data driven, Analytical & Detail Oriented',
            'ORGANIZER',
            'You like systematic structured approach & follow rules. And place value on power and reputation.',
          ],
        },
      ];

      const skills = [];

      for (let i = 0; i < 3; i++) {
        skills.push(data[4]['skillsData'][i]);
      }

      const recommendedCourses = data[8]['recommendedCourses'].split(',');
      const recommendedCourseId = data[10]['recommendedCourseId'];
      const course = data[8]['recommendedCourses'];

      const n = data[2]['results'];

      let max = 0;
      let maxkey = '';
      let maxMsg = '';

      n.map((x) => {
        if (max < Object.values(x)[0]) {
          max = Object.values(x)[0];
          maxkey = Object.keys(x)[0];
        }
      });
      riasec.map((n) => {
        if (Object.keys(n)[0] == maxkey) {
          maxMsg = Object.values(n)[0];
        }
      });

      let interest = [];
      data[9]['sorted_dim'].map((n) => {
        interest.push(n['type']);
      });

      const newData = {
        card1: maxMsg,
        card2: [Object.values(data[1])[0], skills],
        card3: recommendedCourses,
        traits: {
          interest: interest,
          abilities: data[3]['abilities'],
          skillsData: data[4]['skillsData'],
          knowledge: data[5]['knowledge'],
          workValues: data[6]['work_values'],
          workStyles: data[7]['work_styles'],
        },
      };

      if (tester === 'veranda') {
        await axios
          .post(`${BASE_API}/userdetails/verandaUsers`, {
            uid: userId,
            report: newData,
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        localStorage.setItem('report', JSON.stringify(newData));
        localStorage.setItem(
          'recommendedCourseId',
          recommendedCourseId.toString()
        );
        localStorage.setItem('course', course);
      }

      if (!localStorage.getItem('leadsend') === true && !tester) {
        leadAPI();
      }
      setReportFetched(true);

      dispatch({ type: ASSESSMENT_REPORT_SUCCESS, payload: newData });
    } catch (error) {
      dispatch({ type: ASSESSMENT_REPORT_FAIL, payload: error.message });
    }
  };

import React from 'react';
import SubLogin from './sub-component/SubLogin';

const Login = () => {
  return (
    <>
      <SubLogin />
    </>
  );
};

export default Login;
